<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Industry Club/Group Customised Surveys
      </h2>
      <img
        src="../../assets/img/tools_Industry_club_group_customised_survey.jpg"
        alt="Industry Club/Group Customised Surveys Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >
        <p class="py-4">
          Conventional surveys may not be able to address the needs of companies with
          specialised positions or in niche industries.
          <span class="text-red-600"> MRC  (ex-HRBS) </span> conducts numerous customised/sponsored
          surveys to target
          the participants’ specific needs based on:
        </p>
        <br>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Peer Group / Industry</li>
          <li>Compensation Items</li>
          <li>Benefits Items</li>
          <li>Locations</li>
          <li>Positions, etc</li>
        </ul>
        <br>
        <p class="py-4">
            Dedicated teams of consultants manage each survey professionally throughout the entire
            survey process – from drafting of survey profile and data collection material,
            through solicitation of participants, data collection, data checking and verification,
            to delivery of survey reports.
        </p>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
